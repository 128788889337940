import { render, staticRenderFns } from "./PasswordSetupDialog.vue?vue&type=template&id=5a31afa6&scoped=true"
import script from "./PasswordSetupDialog.vue?vue&type=script&lang=js"
export * from "./PasswordSetupDialog.vue?vue&type=script&lang=js"
import style0 from "./PasswordSetupDialog.less?vue&type=style&index=0&id=5a31afa6&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a31afa6",
  null
  
)

export default component.exports