import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhHK from 'vant/lib/locale/lang/zh-HK'
import zhTW from 'vant/lib/locale/lang/zh-TW'
import enLocale from './en'
import zhCNLocale from './zh_cn'
import zhHKLocale from './zh_hk'
import zhTWLocale from './zh_tw'
import idLocale from './id'
import jaLocale from './ja'
import kmLocale from './km'
import koLocale from './ko'
import msLocale from './ms'
import thLocale from './th'
import tlLocale from './tl'
import viLocale from './vi'
import loLocale from './lo'
import ruLocale from './ru'
import kyLocale from './ky'
import uzLocale from './uz'
import fiLocale from './fi'
Vue.use(VueI18n)

const messages = {
  en: {
    ...enUS,
    ...enLocale,
  },
  'zh-CN': {
    ...zhCN,
    ...zhCNLocale,
  },
  'zh-HK': {
    ...zhHK,
    ...zhHKLocale,
  },
  'zh-TW': {
    ...zhTW,
    ...zhTWLocale,
  },
  id: {
    ...enUS,
    ...idLocale,
  },
  ja: {
    ...enUS,
    ...jaLocale,
  },
  km: {
    ...enUS,
    ...kmLocale,
  },
  ko: {
    ...enUS,
    ...koLocale,
  },
  ms: {
    ...enUS,
    ...msLocale,
  },
  th: {
    ...enUS,
    ...thLocale,
  },
  tl: {
    ...enUS,
    ...tlLocale,
  },
  vi: {
    ...enUS,
    ...viLocale,
  },
  lo: {
    ...enUS,
    ...loLocale,
  },
  ru: {
    ...enUS,
    ...ruLocale,
  },
  ky: {
    ...enUS,
    ...kyLocale,
  },
  uz: {
    ...enUS,
    ...uzLocale,
  },
  fi: {
    ...enUS,
    ...fiLocale,
  },
}

const i18n = new VueI18n({
  locale: navigator.language, // 设置默认语言
  messages: messages, // 设置资源文件对象
})

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  // console.log("vantLocales : lang=" + lang)
  if (lang === 'zh-CN') {
    Locale.use(lang, zhCN)
  } else {
    Locale.use(lang, enUS)
  }
}

const languageArray = [
  { lang: 'en', name: 'English' },
  { lang: 'zh-CN', name: '简体中文' },
  { lang: 'zh-HK', name: '繁體中文(香港/澳門)' },
  { lang: 'zh-TW', name: '繁體中文(臺灣)' },
  // 日语
  { lang: 'ja', name: '日本語' },
  // 印度尼西亚语
  { lang: 'id', name: 'Indonesia' },
  // 柬埔寨语
  { lang: 'km', name: 'ភាសាខ្មែរ' },
  // 韩语
  { lang: 'ko', name: '한국어' },
  // 马来语
  { lang: 'ms', name: 'Melayu' },
  // 泰语
  { lang: 'th', name: 'ภาษาไทย' },
  // 他加禄语(菲利宾语)
  { lang: 'tl', name: 'Tagálog' },
  // 越南文
  { lang: 'vi', name: 'Tiếng Việt' },
  // 老挝语
  { lang: 'lo', name: 'ພາສາລາວ' },
  // 俄语
  { lang: 'ru', name: 'Русский язык' },
  // 吉尔吉斯语
  { lang: 'ky', name: 'Кыргыз тили' },
  // 乌兹别克语
  { lang: 'uz', name: 'Oʻzbek tili' },
  // 芬兰语
  { lang: 'fi', name: 'Suomi kieli' },
  // // 德语
  // { lang: 'de', name: 'Deutsch' },
  // // 荷兰
  // { lang: 'nl', name: 'Nederlands' },
  // // 西班牙语
  // { lang: 'es', name: 'Español' },
  // // 阿拉伯
  // { lang: 'ar', name: 'اللغة العربية' },
]

function getLanguageArray() {
  return languageArray;
}

function getLang(lang) {
  for (let index in languageArray) {
    if (lang === languageArray[index].lang) {
      return lang;
    }
  }
  return "en";
}

export { i18n, vantLocales, getLanguageArray, getLang }
