export default {
  app: {
    orgCode: "Konserni/Brändin numero",
    orgCodePlaceholder: "Anna ryhmän/tuotemerkin numero",
    username: "Tili",
    usernamePlaceholder: "Anna tilinumero",
    password: "Salasana",
    passwordPlaceholder: "Anna salasana",
    captcha: "Vahvistuskoodi",
    captchaPlaceholder: "Anna vahvistuskoodi",
    captchaExpired: "Päivitä vahvistuskoodi.",
    login: "Kirjaudu sisään",
    remember: "Muista kirjautumistiedot",
    switchPC: "Vaihda taustalle",
    setupPassword: "Aseta salasana",
    newPassword: "Uusi salasana",
    newPasswordPlaceholder: "Syötä uusi salasana",
    retypePassword: "Syötä uusi salasana uudelleen",
    retypePasswordPlaceholder: "Syötä uusi salasana uudelleen",
    setupPasswordRule: "Salasana on 6-32 merkkiä pitkä ja sen tulee sisältää kaksi tai useampia numeroiden, kirjainten, symbolien jne. yhdistelmää.",
    setupPasswordRuleRefer: "Salasana on 6-32 merkkiä pitkä ja sen tulee sisältää kaksi tai useampia numeroiden, kirjainten, symbolien jne. yhdistelmää. Syötä uudelleen.",
    setupPasswordOk: "Varma",
    setupPasswordDiffer: "Kaksi kertaa annetut salasanat ovat epäjohdonmukaisia, anna uudelleen.",
    err_def: "Kirjautuminen epäonnistui, päivitä sivu ja yritä uudelleen.",
    err_2000: "Vahvistuskoodi syötettiin väärin.",
    err_2010: "Kirjautumistiedot syötettiin väärin.",
    err_4001: "Tämä tili ei ole valtuutettu mihinkään toimintoon.",
    err_4011: "Myymälä on poistettu käytöstä, et voi kirjautua",
    err_4012: "Franchise-saaja on poistettu käytöstä, eikä hän voi kirjautua sisään.",
    err_4013: "Brändi on poistettu käytöstä, et voi kirjautua.",
    err_4014: "Konserni on poistettu käytöstä, et voi kirjautua sisään.",
    err_4015: "Alue on poistettu käytöstä, eikä sinne voi kirjautua.",
    err_5001: "Salasanan asettaminen epäonnistui, yritä uudelleen.",
  },
}