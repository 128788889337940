<template>
    <div class="dialog" v-if="visible">
        <div class="dialog-content">
            <div style="float: right;">
                <van-icon name="close" @click="cancelSetup()" />
            </div>
            <div class="div-title">{{ $t("app.setupPassword") }}</div>
            <div class="div-hint">{{ $t('app.setupPasswordRule') }}</div>
            <div class="div-body">
                <div class="div-row">
                <div>{{ $t("app.newPassword") }}</div>
                <password-field
                    ref="inputPassword"
                    v-model="password"
                    :placeholder="$t('app.newPasswordPlaceholder')"
                    @enter="focusToRetypePassword()"
                />
            </div>
            <div class="div-row">
                <div>{{ $t("app.retypePassword") }}</div>
                <password-field
                    ref="retypePassword"
                    v-model="password2"
                    :placeholder="$t('app.retypePasswordPlaceholder')"
                    @enter="submitSetup()"
                />
            </div>
            <div class="div-row">
                <van-button
                    type="primary"
                    size="small"
                    style="width: 100%"
                    @click="submitSetup()"
                    >{{ $t("app.setupPasswordOk") }}</van-button
                >
            </div>
            </div>
        </div>
    </div>
</template>
   
<script>
    import PasswordField from "@/components/PasswordField.vue";
    import { saltPassword } from '@/util/md5';
    import { setupPassword } from "@/api/api";

    export default {
        name: "PasswordSetupDialog",
        componentName: "PasswordSetupDialog",
        model: {},
        props: {},
        components: {
            PasswordField,
        },
        data() {
            return {
                visible: false,
                permit: "",
                user: "",
                password: "",
                password2: "",
            };
        },
        methods: {
            openDialog(permit, user) {
                this.permit = permit;
                this.user = user;
                this.visible = true;
            },
            focusToInputPassword() {
                this.$refs.inputPassword.focus();
            },
            focusToRetypePassword() {
                this.$refs.retypePassword.focus();
            },
            submitSetup() {
                if (!this.password || this.password.trim().length === 0) {
                    this.$toast({
                        message: this.$t("app.newPasswordPlaceholder"),
                        className: "inexa",
                        position: "top",
                    });
                    this.focusToInputPassword();
                    return;
                }
                var strongRegex = new RegExp(
                    "^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,32}$"
                );
                if (!strongRegex.test(this.password)) {
                    this.$toast({
                        message: this.$t("app.setupPasswordRuleRefer"),
                        className: "inexa",
                        position: "top",
                    });
                    this.focusToInputPassword();
                    return;
                }
                if (!this.password2 || this.password2.trim().length === 0) {
                    this.$toast({
                        message: this.$t("app.retypePasswordPlaceholder"),
                        className: "inexa",
                        position: "top",
                    });
                    this.focusToRetypePassword();
                    return;
                }
                if (this.password !== this.password2) {
                    this.$toast({
                        message: this.$t("app.setupPasswordDiffer"),
                        className: "inexa",
                        position: "top",
                    });
                    this.focusToRetypePassword();
                    return;
                }

                var params = {
                    setupPasswordPermit: this.permit,
                    setupPasswordUser: this.user,
                    newPassword: saltPassword(this.password),
                };
                setupPassword(params).then((rtn) => {
                    console.log("setupPassword : code=" + rtn.code + ", message=" + rtn.message);
                    if (rtn.code === 200) {
                        this.visible = false;
                        this.$emit("submit", rtn.data);
                    } else {
                        let msg = this.$t("app.err_" + rtn.code);
                        if (msg.startsWith("app.err_")) {
                            msg = this.$t("app.err_def");
                        }
                        this.$toast({
                            message: msg,
                            className: "inexa",
                            position: "top",
                        });
                        this.cancelSetup();
                    }
                });
            },
            cancelSetup() {
                console.log("cancelSetup...");
                this.permit = "";
                this.user = "";
                this.password = "";
                this.password2 = "";
                this.visible = false;
                this.$emit("cancel");
            },
        },
    };
</script>
<style lang="less" src="./PasswordSetupDialog.less" scoped />