import 'lib-flexible'
import '@/util/filter'
import '@vant/touch-emulator';
import 'vant/lib/index.less'
import { i18n, vantLocales } from '@/lang/index'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

// UI组件引用
import {
  Button,
  Field,
  Icon,
  Toast,
} from 'vant'

Vue.use(Button)
  .use(Field)
  .use(Icon)
  .use(Toast);

// 国际化
vantLocales(i18n.locale);
Vue.config.productionTip = false;

const FastClick = require('fastclick')
FastClick.attach(document.body)
/*部分IOS出现无法点击的情况,在ios上点击失效，多次点击才可以获取焦点的问题，那么导致这个问题所在的原因是因为项目中引入了FastClick 这个是解决移动端延迟300毫秒的优化*/
/*当使用FastClick 时，输入框在ios上点击输入调取手机自带输入键盘不灵敏，有时候甚至点不出来。而安卓上完全没问题。这个原因是因为FastClick的点击穿透*/
var deviceIsWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0
var deviceIsIOS =
  /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone
FastClick.prototype.focus = function (targetElement) {
  var length
  if (
    deviceIsIOS &&
    targetElement.setSelectionRange &&
    targetElement.type.indexOf('date') !== 0 &&
    targetElement.type !== 'time' &&
    targetElement.type !== 'month' &&
    targetElement.type !== 'email'
  ) {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app')
